/**
 * @generated SignedSource<<53523949b3900a99725d02bd7421b96c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespSearchResultContainer_itemSearch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedProductAdWrapper_itemSearch" | "SharedItemTile_itemSearch" | "useMeasurementUnit_itemSearch" | "useMergedSbAndSponsoredItems_itemSearch" | "useSbSharedItemTracking_itemSearch">;
  readonly " $fragmentType": "SbRespSearchResultContainer_itemSearch";
};
export type SbRespSearchResultContainer_itemSearch$key = {
  readonly " $data"?: SbRespSearchResultContainer_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSearchResultContainer_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespSearchResultContainer_itemSearch",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useMeasurementUnit_itemSearch",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParam",
          "kind": "LinkedField",
          "name": "appliedFilters",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchBrowseParsedParamValue",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlLabel",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedProductAdWrapper_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMergedSbAndSponsoredItems_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "6ab7c4beffe4eb15c59b7eded45157da";

export default node;
