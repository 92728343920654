import { FC } from 'react';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { TRADE, CONSUMER } from 'dibs-buyer-layout/exports/userTypeEnums';
import { ProductTileAd } from '../../components/global/Ads/ProductTileAd';
import { getProductTileAdMap } from '../../utils/adHelpers';
import { useSbSelector } from '../../reducers/useSbSelector';

import { SbSharedProductAdWrapper_itemSearch$data } from './__generated__/SbSharedProductAdWrapper_itemSearch.graphql';

type Props = {
    index: number;
    pageCol?: number;
    itemSearch: SbSharedProductAdWrapper_itemSearch$data;
};

export const SbSharedProductAdWrapperComponent: FC<Props> = ({ index, pageCol, itemSearch }) => {
    const relayVariables = useSbSelector(state => state.relayVariables.variables);
    // reading isTrade value from relay vars b/c we need to get correct ad config values
    // during first render when google tag script starts loading ads.
    const showTradeAds = relayVariables.isTrade;

    const adMap = getProductTileAdMap({
        uriRef: itemSearch?.displayUriRef || '',
        pageCol,
        pageSize: relayVariables.first ?? 60,
        userType: showTradeAds ? TRADE : CONSUMER,
    });

    const ad = adMap[index + 1]; // 1-indexed

    if (!ad) {
        return null;
    }

    const { adId, elementId } = ad;
    return <ProductTileAd adId={adId} elementId={elementId} />;
};

export const SbSharedProductAdWrapper = createFragmentContainer(SbSharedProductAdWrapperComponent, {
    itemSearch: graphql`
        fragment SbSharedProductAdWrapper_itemSearch on ItemSearchQueryConnection {
            displayUriRef
        }
    `,
});
